import React, { useState } from 'react';


export default props => {

  var initValue = window.sessionStorage.getItem('subject') || 'IB1234';

  var [subject, setSubject] = useState(initValue);
  
  subject = Math.random().toString(36).substring(2);    

  function onContinue(e) {
    e.preventDefault();
    window.sessionStorage.setItem('subject', subject);
    props.history.push('/securitydetails');
  };

  return (
  <div className="san-login">
    <h1 className="title">Use your Online Banking details to log on</h1>
    <div className="columns is-mobile">
      <div className="loginType column selected">
        <i className="far fa-user fa-4x"></i>
        <span className="loginTypeLabel">Personal</span>
      </div>

      <div className="loginType column">
        <i className="fa fa-briefcase fa-4x"></i>
        <span className="loginTypeLabel">Business</span>
      </div>

      <div className="loginType column">
        <i className="far fa-building fa-4x"></i>
        <span className="loginTypeLabel">Corporate</span>
      </div>
    </div>

    <div className="field">
      <label className="label" htmlFor="code">Personal / Customer ID</label>
      <div className="control">
        <input className="input" type="text" id="pid" onChange={e => setSubject(e.target.value)} value={subject}/>
      </div>
    </div>

    <div className="field">
      <input type="checkbox" id="checkbox" className="is-checkradio nml" />
      <label htmlFor="checkbox"><span>Remember my ID</span></label>
    </div>

    <div className="column is-three-fifths is-offset-one-fifth">
      <div className="buttons">
        <button type="submit" className="button is-primary is-fullwidth" onClick={onContinue}>
          <span>Continue</span>
        </button>
      </div>
      <a href="#">I don't know my login details</a>
    </div>

  </div>
);
  }