import React from 'react';
import base32 from 'base32';

export default class Finish extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    setTimeout(() => {
      var code = base32.encode(window.sessionStorage.getItem('subject'));
      var url = `${process.env.REACT_APP_IDP_URL}` + '/obcallback?code=' + code;
      window.location = url;
    }, 2000)
  }

  render() {
    return (

    <div className="san-finish">
      <i className="fas fa-sync fa-4x fa-spin"></i>
      <h1 className="title">That's it from us</h1>

      <h2 className="subtitle">We're sending you back to OBId.</h2>

      <h2 className="subtitle">Please don't close this window.</h2>
    </div>
  );
  }
}