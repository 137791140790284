import React from 'react';


export default props => (

  <div className="san-share">
    <i className="fas fa-exchange-alt fa-4x"></i>
    <h1 className="title">Share your account</h1>

    <h2 className="subtitle">You've got one account (ending 1234) that you're able to share with OBId.</h2>

    <div className="column is-half is-offset-one-quarter">
      <div className="buttons">
        <button type="submit" className="button is-primary is-fullwidth" onClick={() => props.history.push('/authorise')}>
          <span>Okay</span>
        </button>
      </div>
      <a href="#">Information you'll share</a>
    </div>

  </div>
);