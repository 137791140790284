import React from 'react';
import NavMenu from './NavMenu';

export default props => (
  <div>
    <section className="section header">
      <NavMenu />
    </section>
    <section className="section main">
      <div className="maincolumn column is-three-fifths is-offset-one-fifth">
        {props.children}
      </div>
    </section>
  </div>
);
