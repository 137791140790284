import React from 'react';
import { Link, Route } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import Layout from './Layout';
import Login from './components/login';
import SecurityDetails from './components/securitydetails';
import Share from './components/share';
import Authorise from './components/authorize';
import Finish from './components/finish';


class App extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  render() {
    return (
      <Layout>
        <Route exact path='/' component={Login} />
        <Route path='/login' component={Login} />
        <Route path='/securitydetails' component={SecurityDetails} />
        <Route path='/share' component={Share} />
        <Route path='/authorise' component={Authorise} />
        <Route path='/finish' component={Finish} />
      </Layout>
    );
  }
}

export default App;
