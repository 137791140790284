import React from 'react';


export default props => (

  <div className="san-authorize">
    <i className="fas fa-mobile-alt fa-4x"></i>
    <h1 className="title">Now authorise your request</h1>

    <div className="field">
      <label className="label" htmlFor="code">One Time Password sent to +447******870</label>
      <div className="control">
        <input className="input" type="text" id="pid" />
      </div>
    </div>

    <fieldset>
      <legend>Security Number</legend>
    
      <div className="columns is-mobile">

        <div className="field column is-1 is-offset-3">
          <label className="label" htmlFor="code">1st</label>
          <div className="control">
            <input className="input" type="text" id="pid" maxLength="1" />
          </div>
        </div>

        <div className="field column is-1">
          <label className="label" htmlFor="code">2nd</label>
          <div className="control">
            <input className="input" type="text" id="pid" maxLength="1" />
          </div>
        </div>

        <div className="field column is-1 ">
          <label className="label" htmlFor="code">&nbsp;</label>
          <div className="control">
            <input className="input" type="text" disabled="disabled" id="pid" maxLength="1" />
          </div>
        </div>

        <div className="field column is-1">
          <label className="label" htmlFor="code">4th</label>
          <div className="control">
            <input className="input" type="text" id="pid" maxLength="1" />
          </div>
        </div>

        <div className="field column is-1 ">
          <label className="label" htmlFor="code">&nbsp;</label>
          <div className="control">
            <input className="input" type="text" disabled="disabled" id="pid" maxLength="1" />
          </div>
        </div>
      </div>
    </fieldset>

    <div className="column is-three-fifths is-offset-one-fifth">
      <div className="buttons">
        <button type="submit" className="button is-primary is-fullwidth" onClick={() => props.history.push('/finish')}>
          <span>Submit</span>
        </button>
      </div>
    </div>

  </div>
);