import React from 'react';


export default props => (

  <div className="san-securitydetails">
    <i className="fas fa-lock fa-4x"></i>
    <h1 className="title">Enter your security details</h1>
    <fieldset>
      <legend>Password</legend>
    
      <div className="columns is-mobile">

        <div className="field column is-1 is-offset-4">
          <label className="label" htmlFor="code">2nd</label>
          <div className="control">
            <input className="input" type="password" id="pid" maxLength="1" />
          </div>
        </div>

        <div className="field column is-1">
          <label className="label" htmlFor="code">3rd</label>
          <div className="control">
            <input className="input" type="password" id="pid" maxLength="1" />
          </div>
        </div>

        <div className="field column is-1 ">
          <label className="label" htmlFor="code">6th</label>
          <div className="control">
            <input className="input" type="password" id="pid" maxLength="1" />
          </div>
        </div>

      </div>
    </fieldset>
    <fieldset>
      <legend>Security Number</legend>
    
      <div className="columns is-mobile">

        <div className="field column is-1 is-offset-3">
          <label className="label" htmlFor="code">1st</label>
          <div className="control">
            <input className="input" type="text" id="pid" maxLength="1" />
          </div>
        </div>

        <div className="field column is-1">
          <label className="label" htmlFor="code">2nd</label>
          <div className="control">
            <input className="input" type="text" id="pid" maxLength="1" />
          </div>
        </div>

        <div className="field column is-1 ">
          <label className="label" htmlFor="code">&nbsp;</label>
          <div className="control">
            <input className="input" type="text" disabled="disabled" id="pid" maxLength="1" />
          </div>
        </div>

        <div className="field column is-1">
          <label className="label" htmlFor="code">4th</label>
          <div className="control">
            <input className="input" type="text" id="pid" maxLength="1" />
          </div>
        </div>

        <div className="field column is-1 ">
          <label className="label" htmlFor="code">&nbsp;</label>
          <div className="control">
            <input className="input" type="text" disabled="disabled" id="pid" maxLength="1" />
          </div>
        </div>
      </div>
    </fieldset>

    <div className="column is-three-fifths is-offset-one-fifth">
      <div className="buttons">
        <button type="submit" className="button is-primary is-fullwidth" onClick={() => props.history.push('/share')}>
          <span>Log on</span>
        </button>
      </div>
      <a href="#">I've forgotten my security details</a>
    </div>

  </div>
);