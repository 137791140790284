import React from 'react';
import { Link } from 'react-router-dom';

class NavMenu extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      expanded: false
    };
  }

  componentDidMount() {
  }

  doStuff() {
    this.setState(prevState => ({
      expanded: !prevState.expanded
    }));
  }

  render() {
    return (
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="column is-half is-offset-one-quarter">
          <div className="navbar-brand">
            <a className="navbar-item" href="https://www.provemy.id">              
            </a>

            <a onClick={this.doStuff} role="button" className={"navbar-burger burger" + (this.state.expanded ? " is-active" : "")} aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>

          <div id="navbarBasicExample" className={"navbar-menu" + (this.state.expanded ? " is-active" : "")}>
            <div className="navbar-start">
              <a className="navbar-item">
                Home
              </a>

              <a className="navbar-item">
                Documentation
              </a>

              <div className="navbar-item has-dropdown is-hoverable">
                <a className="navbar-link">
                  More
                </a>

                <div className="navbar-dropdown">
                  <a className="navbar-item">
                    About
                  </a>
                  <a className="navbar-item">
                    Jobs
                  </a>
                  <a className="navbar-item">
                    Contact
                  </a>
                  <hr className="navbar-divider" />
                  <a className="navbar-item">
                    Report an issue
                  </a>
                </div>
              </div>
            </div>

            <div className="navbar-end">
              {this.props.loggedIn ? (
                <div className="navbar-item has-dropdown is-hoverable">
                  <a className="navbar-link">
                    {this.props.auth.email}
                  </a>

                  <div className="navbar-dropdown">
                    <a className="navbar-item" onClick={this.signOut}>Sign out</a>
                  </div>
                </div>
              ) : (
                  <div className="navbar-item">

                    <div className="buttons">
                      <Link to="/signup" className="button is-primary"><strong>Sign up</strong></Link>
                      <Link to="/login" className="button is-light">Log in</Link>
                    </div>

                  </div>
                )}
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default NavMenu;
